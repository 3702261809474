.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(33, 206, 153);
    border-radius: 50%;
    animation: lds-ring 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.045s;
    }

    &:nth-child(2) {
      animation-delay: -0.03s;
    }

    &:nth-child(3) {
      animation-delay: -0.015s;
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
    border-color: rgb(25, 255, 184) transparent transparent transparent;
  }
  50% {
    transform: rotate(360deg);
    border-color: rgb(18, 139, 101) transparent transparent transparent;
  }
  100% {
    transform: rotate(360deg);
    border-color: rgb(25, 255, 184) transparent transparent transparent;
  }
}
