.dashboard {
  .big {
    width: 90px;
    height: 90px;
    text-align: center;

    img {
      margin-top: 23px;
    }
  }

  .alert-light {
    @include customize(theme) {
      background-color: getValue(backgroundColor);
    }
    border: none;
  }

  hr {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    @include customize(theme) {
      color: getValue(pColor);
    }
  }

  .rounded-card {
    margin: auto;
  }

  .CircularProgressbar-text {
    font-size: 8px !important;
  }

  .progress-bar-circle span {
    @include customize(theme) {
      color: getValue(darkenColor);
    }
    font-size: 18px;
  }

  .dashboard-img img {
    background-color: white;
    margin: 0;
    border-radius: 50% !important;
    border: 2px solid #21ce99;
  }

  .institute-link {
    @include customize(theme) {
      color: getValue(linkColor);
    }
  }
  .institute-link:hover {
    @include customize(theme) {
      color: getValue(buttonTextColor);
    }
  }
}

.alert {
  margin-bottom: 0;
  padding: 0;
}

.alert-light hr {
  border-top-color: #818182;
}
