.institute {
  table,
  thead,
  th,
  tbody,
  tr,
  td {
    @include customize(theme) {
      border: 2px solid getValue(primaryColor);
      color: getValue(darkColor);
    }
    background-color: initial;
  }

  table:hover {
    @include customize(theme) {
      color: getValue(darkColor);
    }
    background-color: initial;
  }

  table tr:hover {
    @include customize(theme) {
      color: getValue(darkColor);
    }
    background-color: initial;
  }
  label {
    @include customize(theme) {
      color: getValue(darkColor);
    }
  }
}

.payment-history {
  Table > tbody > tr:nth-child(even) > td,
  Table > tbody > tr:nth-child(even) > th {
    background-color: #f5f5f5;
  }
  .img-pointer {
    cursor: pointer;
  }
}
