.home-page {
  max-width: 1100px;

  .row {
    display: flex;
    align-items: center;
  }

  h2 {
    font-size: 60px;
    font-style: normal;
    font-weight: 500;
  }

  p {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }
}

.btn-home-page {
  font-size: 18px !important;
}

.home-page-download {
  margin-top: 30px;

  p {
    font-size: 15px;
    padding: 0;
    margin: 0;
  }
}

.google-play {
  height: 50px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  padding: 0.5rem 1.5rem;
}

.landing-img {
  width: 100%;
  height: auto;
}

.toast {
  max-width: 100%;
  position: absolute;
  top: 120px;
  left: calc(50vw - 200px);
  background-color: #d4d4d4;
}

@media (max-width: 460px) {
  .toast {
    max-width: 95%;
    top: 130px;
    left: 10px;
    h3 {
      font-size: 1.25em;
    }
  }
}
