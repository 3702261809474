.upload-col {
  margin-top: 10px;
  position: relative;
  height: 200px;
  width: 200px;

  .upload-warp {
    .upload-div {
      overflow: hidden;
      margin: auto 0;
      text-align: center;
      position: absolute;
      z-index: 10;
      @include customize(theme) {
        border: 1px dashed getValue(primaryColor);
        background-color: getValue(uploadCardColor);
      }
      height: 200px;
      width: 170px;

      & h6 {
        padding: 20px 0px;
        height: 50px;
        @include customize(theme) {
          color: getValue(darkColor);
        }
      }

      & p {
        @include customize(theme) {
          color: getValue(darkColor);
        }
        word-break: break-word;
      }

      & .upload-input {
        opacity: 0;
      }
    }

    &.upload-input {
      z-index: 1;
      margin: 20px;
    }

    .upload-img {
      max-height: 60px !important;
      z-index: 1000;
    }
  }
}
