$green-light: #21ce99;

$charcoal: #484848;
$blue-charcoal: #212529;
$dark-charcoal: #333333;

$white: #ffffff;
$white-smoke: #f3f3f3;

$cyan-blue: #34495e;
$dark-grayish-blue: #818182;

$black-pearl: #040d14;

$alizarin: #ec1b30;

$guardsman-red: #a82b37;

$wild-watermelon: #ff5c5d;
