.terms-condition-model {
  width: 770px;
  padding: 20px 0;
  border-radius: 7px;
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
}

.terms-condition {
  label {
    color: #ffffff;
  }

  button {
    margin-top: 8px;
  }
}

.reapply-modal-content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 25px;
  max-width: 500px;
}

svg {
  fill: #fff;
}

.demat-agreement-modal {
  ol {
    list-style: none;
  }
  ol > li.one:before {
    counter-increment: roman;
    content: '१.';
    margin-right: 0.5em;
  }
  ol > li.two:before {
    counter-increment: roman;
    content: '२.';
    margin-right: 0.5em;
  }
  ol > li.three:before {
    counter-increment: roman;
    content: '३.';
    margin-right: 0.5em;
  }
  ol > li.four:before {
    counter-increment: roman;
    content: '४.';
    margin-right: 0.5em;
  }
  ol > li.five:before {
    counter-increment: roman;
    content: '५.';
    margin-right: 0.5em;
  }
  ol > li.six:before {
    counter-increment: roman;
    content: '६.';
    margin-right: 0.5em;
  }
  ol > li.seven:before {
    counter-increment: roman;
    content: '७.';
    margin-right: 0.5em;
  }
  ol > li.eight:before {
    counter-increment: roman;
    content: '८.';
    margin-right: 0.5em;
  }
  ol > li.nine:before {
    counter-increment: roman;
    content: '९.';
    margin-right: 0.5em;
  }
  ol > li.ten:before {
    counter-increment: roman;
    content: '१०.';
    margin-right: 0.5em;
  }
  ol > li.eleven:before {
    counter-increment: roman;
    content: '११.';
    margin-right: 0.5em;
  }
  ol > li.twelve:before {
    counter-increment: roman;
    content: '१२.';
    margin-right: 0.5em;
  }
}
