.verification-modal {
  width: 570px !important;
  padding: 20px 0 !important;
  border-radius: 7px !important;
}

.react-responsive-modal-overlay {
  z-index: 2000 !important;
}

.verification {
  .btn-code {
    @include customize(theme) {
      color: getValue(buttonTextColor);
    }
  }

  label {
    color: #ffffff;
  }

  button {
    margin-top: 8px;
  }

  input.verification-code,
  &:focus {
    @include customize(theme) {
      border: 1px solid getValue(buttonBorderColor);
    }
    outline: none !important;
    padding-left: 15px !important;
  }
}

svg {
  @include customize(theme) {
    fill: getValue(primaryColor);
  }
}

.styles_react-code-input__CRulA input,
.styles_react-code-input__CRulA input:focus {
  @include customize(theme) {
    color: getValue(darkenColor);
    caret-color: getValue(buttonBorderColor);
    background-color: getValue(backgroundColor);
  }
}

.styles_react-code-input__CRulA > input:first-child {
  border-radius: 0.25rem !important;
}

.styles_react-code-input__CRulA > input:last-child {
  border-radius: 0.25rem !important;
  @include customize(theme) {
    border: 1px solid getValue(buttonBorderColor);
  }
}

.styles_react-code-input__CRulA > input:focus + input {
  @include customize(theme) {
    border-left: 1px solid getValue(buttonBorderColor);
  }
}

.styles_react-code-input__CRulA > input {
  @include customize(theme) {
    border: 1px solid getValue(buttonBorderColor);
  }
  outline: none !important;
  text-align: left !important;
  padding-left: 15px !important;
  margin-right: 10px !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 1rem !important;
  font-weight: 400 !important;
}

.styles_react-code-input__CRulA > input:focus {
  @include customize(theme) {
    border: 1px solid getValue(buttonBorderColor);
  }
  outline: none !important;
}

.styles_react-code-input__CRulA {
  display: flex;
}

.styles_react-code-input-container__tpiKG {
  position: unset !important;
}
