.profile-img img {
  background-color: white;
  margin: 0;
  border-radius: 50% !important;
  @include customize(theme) {
    border: 2px solid getValue(darkenColor);
  }
}

.no-img img {
  margin: 0;
  opacity: 0.5;
  border-radius: 50% !important;
  @include customize(theme) {
    border: 2px solid getValue(darkenColor);
  }
}

.full-name {
  color: $charcoal;
}
