html,
body {
  height: 100%;
  margin: 0;
}

body {
  font-size: 0.875rem;
  font-family: Capsule Sans Text, system-ui, -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif;
}

input.form-control,
input.form-control:focus,
select.form-control {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #818181;
  outline: none !important;
}

input.form-control {
  padding: 0px;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='number']:focus,
[type='text'].form-control:focus,
[type='password'].form-control:focus,
[type='email'].form-control:focus,
[type='tel'].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0;
}

a.btn-code {
  text-decoration: none;
}

.input-group-prefix {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  border-radius: 0.25rem;
  padding-right: 8px;
}

.width-50 {
  width: 50px;
  margin-right: 15px;
}

.width-40 {
  width: 40px;
  margin-right: 10px;
}

.display-inline {
  display: inline-flex;
}

.rounded-card {
  width: 40px;
  height: 40px;
  text-align: center;
}

.rounded-card img {
  margin-top: 8px;
}

.green-round {
  border: 2px solid #21ce99;
}

.red-round {
  border: 2px solid #e2603f;
}

.white-round {
  border: 2px solid #ffffff;
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.btn-width {
  width: 88px;
}

.error {
  color: #e2603f !important;
}

.success {
  color: #21ce99 !important;
}

.main-footer {
  color: #4c5756;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
  bottom: 0;
  width: 100%;
  opacity: 1;
  background-color: rgba(48, 48, 48, 0.1);
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1;
}

.checkbox {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
}

.checkbox > span {
  color: #ffffff;
  padding: 0.5rem 0.4rem;
}

.checkbox-no-label > span {
  color: #ffffff;
  padding: 0.5rem 0.4rem;
}

.checkbox > input:checked {
  border: 1px solid #41b883;
  background-color: #34495e;
}

.checkbox > input:active {
  border: 2px solid #ffffff;
}

.required {
  color: #e2603f;
}

.label {
  color: #ffffff;
}

.card-text.user-name {
  font-weight: bold;
}

.not-selected {
  color: #707a94 !important;
}

.not-selected:focus {
  color: #a82b37 !important;
}

/* from style.css file*/

.navbar-nav {
  float: right;
  margin: 0;
}

.topheader {
  height: 37px;
}

.tollfree {
  float: left;
  margin-left: 255px;
  margin-top: 10px;
}

.tollfree a {
  color: #e3161f;
}

.topmenu {
  list-style: none;
  text-align: right;
  float: right;
  margin-top: 10px;
  margin-bottom: 0;
}

.topmenu li {
  display: inline-block;
  margin-left: 35px;
}

.topmenu li a {
}

.topmenu li a:hover,
.topmenu li a:active,
.topmenu li a:focus {
  color: #e3161f;
}

.topmenu-icons {
  list-style: none;
  text-align: right;
  float: right;
  margin-top: 10px;
  color: #d6040d;
  border-left: 1px solid #e0e0e0;
  padding-left: 15px;
  margin-left: 15px;
}

.topmenu-icons li {
  display: inline-block;
  padding: 0 5px;
  position: relative;
}

.topmenu-icons li {
  display: inline-block;
  padding: 0 5px;
  position: relative;
}

.topmenu-icons li a span {
  background: #333333;
  border-radius: 50px;
  display: block;
  position: absolute;
  top: -3px;
  width: 13px;
  height: 13px;
  color: #fff;
  font-size: 9px;
  text-align: center;
  line-height: 13px;
  left: 12px;
}

.topmenu-icons i {
  color: #d6040d;
  font-size: 15px;
}

.navbar-header {
  position: relative;
}

.menucorner {
  position: absolute;
  top: -50px;
  left: -110px;
}

.navbar-brand {
  position: absolute;
  z-index: 5;
  top: -32px;
  left: 5px;
}

.applynowbtn {
  float: right;
}

#navbar {
  float: right;
}

.navbar {
  padding: 0;
  width: 100%;
  position: static !important;
  margin-bottom: 0;
  background: linear-gradient(
    430deg,
    rgba(231, 27, 36, 1) 50%,
    rgba(214, 4, 13, 1) 100%
  );
}

.navbar-brand > img {
  display: block;
  height: 85px;
}

.navbar-nav > li > a {
  padding-top: 55px;
  padding-bottom: 50px;
}

.navbar-inverse .navbar-nav > li > a {
  color: #333;
  text-transform: uppercase;
  font-weight: 600;
}

.applynowbtn {
  background: #ffffff;
  padding: 8px 30px 5px 30px;
  border-radius: 28px;
  margin-top: 22px;
  margin-bottom: 15px;
  margin-right: 10px;
  color: #d6040d !important;
  text-decoration: none;
  font-weight: 600;
  height: 35px;
  font-size: 14px;
  box-shadow: 0px 3px 3px #00000047;
  text-decoration: none;
}

.applynowbtn:hover {
  transition-delay: 0.1s;
  text-decoration: none;
  box-shadow: 0px 3px 9px #00000087;
}

.menuwhite {
  display: none;
}

@media (min-width: 768px) and (max-width: 979px) {
  .navbar-fixed-top .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .navbar-fixed-top .container {
    max-width: 1000px;
  }

  main#content {
    transition: all ease-out 0.3s;
  }

  .topmenu {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    float: right;
  }

  .navbar-header {
    position: relative;
    text-align: center;
    height: 79px;
  }
}

@media (min-width: 573px) and (max-width: 767px) {
  .applynowbtn {
    justify-content: center;
    padding: 6px 20px 2px 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 33px;
  }
}

@media (max-width: 574px) {
  .topmenu {
    display: none;
  }

  .navbar-header {
    height: 40px;
  }

  .applynowbtn {
    justify-content: center;
    padding: 6px 20px 2px 20px;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 33px;
  }
}
