.payment-modal {
  width: 500px !important;
  padding: 20px 0 !important;
  border-radius: 7px !important;

  form {
    padding: 15px 15px 0px 15px;
  }
  .voucher-upload-img {
    color: $blue-charcoal;
  }
}

.react-responsive-modal-overlay {
  z-index: 2000 !important;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 2050 !important;
}
