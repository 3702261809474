.cancel-modal {
  width: 470px !important;
  padding: 20px 0 !important;
  border-radius: 7px !important;
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
}

.cancel-modal-title {
  @include customize(theme) {
    color: getValue(darkColor);
  }
}
