$themes: (
  red: (
    primaryColor: $charcoal,
    darkenColor: $guardsman-red,
    lightenColor: $wild-watermelon,
    darkColor: $blue-charcoal,
    buttonColor: $alizarin,
    buttonTextColor: $alizarin,
    buttonBorderColor: $alizarin,
    buttonBackgroundColor: $white,
    activeButtonTextColor: $white,
    activeButtonBorderColor: $alizarin,
    activeButtonBackgroundColor: $alizarin,
    cardTextColor: $charcoal,
    backgroundColor: $white,
    checkboxColor: initial,
    checkboxBorderColor: $cyan-blue,
    uploadCardColor: $white,
    linkColor: $charcoal,
    pColor: $dark-charcoal,
    required: red,
    backgroundImage: '',
  ),
  black: (
    primaryColor: $white-smoke,
    darkenColor: $green-light,
    lightenColor: $black-pearl,
    darkColor: $white-smoke,
    buttonColor: $black-pearl,
    buttonTextColor: $white,
    buttonBorderColor: $green-light,
    buttonBackgroundColor: $black-pearl,
    activeButtonTextColor: $black-pearl,
    activeButtonBorderColor: $green-light,
    activeButtonBackgroundColor: $green-light,
    cardTextColor: $white-smoke,
    backgroundColor: $black-pearl,
    checkboxColor: $black-pearl,
    checkboxBorderColor: $white,
    uploadCardColor: $blue-charcoal,
    linkColor: $dark-grayish-blue,
    pColor: $white,
    required: orange,
    backgroundImage: url(../../img/bg_logo.png),
  ),
);

$color-schemes: (
  scheme-default: (
    backgroundColor: #040d14,
  ),
  scheme-cs1: (
    backgroundColor: #ffffff,
  ),
);

/**
 * theme-customize - Change color of a css property based on the currently active theme
 *
 * @param  {theme}     theme name
 *
 * @example
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
 *
 */
@mixin customize($arg: theme) {
  $array: $themes;
  $prefix: theme;

  @if ($arg == theme) {
    $array: $themes;
    $prefix: theme;
  } @else {
    $array: $color-schemes;
    $prefix: color;
  }

  @each $theme, $map in $array {
    .#{$prefix}-#{$theme} & {
      $array-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($array, $theme), '#{$key}');
        $array-map: map-merge(
          $array-map,
          (
            $key: $value,
          )
        ) !global;
      }

      @content;
      $array-map: null !global;
    }
  }
}

@function getValue($key) {
  @return map-get($array-map, $key);
}
