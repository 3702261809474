.kyc .nav-pills .nav-link.active {
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
  border: none !important;
}

.rounded-card {
  margin: auto;
}

.kyc-select {
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
  border: none;
  color: #6c757d;
  padding: 0;
  box-shadow: none !important;
  -moz-appearance: none !important;

  &:focus {
    background: transparent;
    box-shadow: inset 0 0 0;
    color: #495057;
  }
}

.selected {
  @include customize(theme) {
    color: getValue(darkenColor);
  }
}

.kyc-selected {
  @include customize(theme) {
    color: getValue(darkenColor);
  }

  option:first-child {
    color: grey;
    display: none;
  }
}

.select-info option:first-child {
  color: grey;
  display: none;
}

.nav-tabs {
  height: 19px;
  border-bottom: 1px solid #4ccf99;

  .nav-link {
    border: 1px solid #4ccf99;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom: none;
    padding-top: 0px;
    height: 17px !important;

    .active {
      color: #495057;
      background-color: #4bcf99;
      border-color: #4bcf99;
      border-bottom: none;
      padding-top: 0px;
      height: 17px !important;
    }
  }
}

.input-group-append {
  align-items: center;
  margin-left: -25px;
}

.personal-form {
  #resetDateButton {
    border: 1px solid #4ccf99;
    background-color: #4bcf99;
    border-radius: 50%;
    padding: 0;
    width: 20px;
    height: 20px;

    div {
      font-size: 10px;
      color: white;
    }
  }

  .react-datepicker__close-icon::after {
    border: 1px solid #4ccf99;
    background-color: #4bcf99;
    border-radius: 50%;
    padding: 0;
    width: 20px;
    height: 20px;
  }
}

.date-picker-dob {
  display: inline;
  width: 40% !important;
  color: #495057;
}

.grid-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em;

  @media (min-width: 52em) {
    grid-template-columns: repeat(auto-fit, minmax(15em, 1fr));
  }
}

.document-grid-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 0em;

  @media (min-width: 52em) {
    grid-template-columns: repeat(auto-fit, minmax(12em, 1fr));
  }
}
