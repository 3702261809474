body {
  @include customize(theme) {
    background: getValue(backgroundImage);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: right -65px bottom -57px;
    background-color: getValue(backgroundColor);
  }
}

a,
h1,
h2,
h4,
p {
  @include customize(theme) {
    color: getValue(primaryColor);
  }
}

.card {
  @include customize(theme) {
    background-color: getValue(primaryColor);
  }
  border: none;
}

button.btn-green,
a.btn-green {
  @include customize(theme) {
    background-color: getValue(buttonColor);
    border: 2px solid getValue(buttonColor);
  }
  color: #ffffff;
  border-radius: 20px;
  font-size: 13px;
}

button.btn-green:hover,
button.btn-green:focus,
button.btn-green,
a.btn-green:hover,
a.btn-green:focus,
a.btn-green:active a.btn-green:visited {
  @include customize(theme) {
    background-color: getValue(activeButtonBackgroundColor);
    border: 2px solid getValue(activeButtonBorderColor);
    color: getValue(activeButtonTextColor);
  }
  box-shadow: none !important;
}

button.btn-white,
a.btn-white {
  border-radius: 20px;
  font-size: 13px;
}

button.btn-white,
button.btn-white:hover,
button.btn-white:focus,
button.btn-white:active,
button.btn-white:visited,
a.btn-white,
a.btn-white:hover,
a.btn-white:focus,
a.btn-white:active,
a.btn-white:visited {
  @include customize(theme) {
    background-color: getValue(buttonBackgroundColor);
    border: 2px solid getValue(buttonBorderColor);
    color: getValue(buttonTextColor);
  }
  box-shadow: none !important;
}

nav {
  @include customize(theme) {
    //background-color: getValue(backgroundColor);
  }
}

primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  @include customize(theme) {
    background-color: getValue(buttonBackgroundColor);
    color: getValue(buttonTextColor);
    border-color: getValue(buttonBorderColor);
  }
}

.google-play {
  @include customize(theme) {
    background: getValue(buttonColor);
  }
}

.form-control,
.form-control:focus {
  border-radius: 0;
  @include customize(theme) {
    color: getValue(darkenColor);
    background-color: getValue(backgroundColor);
  }
}

.form-control:disabled {
  background-color: #040d14 !important;
}

input,
[contenteditable] {
  color: #fff;
  @include customize(theme) {
    caret-color: getValue(primaryColor);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  @include customize(theme) {
    -webkit-text-fill-color: getValue(darkenColor);
  }
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 0;
}

.label {
  @include customize(theme) {
    color: getValue(darkColor);
  }
}

.alert-light {
  background-color: #ffffff;
  border: none;
}

.card-text {
  padding: 0.75rem 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: none;
  text-align: center;
  @include customize(theme) {
    color: getValue(cardTextColor);
  }
}

.nav-link-color,
.input-label,
.card-footer {
  color: #fff;
}

.nav-link:focus,
.nav-link:hover {
  outline: none;
}

.datepicker-input-postfix {
  position: absolute;
  top: 7px;
  left: 95px;
  @include customize(theme) {
    color: getValue(darkenColor);
  }
  font-size: 16px;
}

.white-round {
  border: 2px solid #ffffff;
  background: #212529;
}

.checkbox > input {
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  @include customize(theme) {
    background-color: getValue(checkboxColor);
    border: 2px solid getValue(checkboxBorderColor);
  }
  cursor: pointer;
}

.checkbox > input:focus {
  @include customize(theme) {
    border: 2px solid getValue(checkboxBorderColor);
  }
}

.checkbox > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  @include customize(theme) {
    color: getValue(darkenColor);
  }
  position: absolute;
  left: 0.25rem;
  top: 0.5rem;
}

.checkbox-2 > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  @include customize(theme) {
    color: getValue(darkenColor);
  }
  position: absolute;
  left: -1rem;
  top: 0.6rem;
}

.checkbox-no-label > input:checked + span::before {
  content: '\2713';
  display: block;
  text-align: center;
  @include customize(theme) {
    color: getValue(darkenColor);
  }
  position: absolute;
  left: 0.25rem;
  top: -0.1rem;
}

form p {
  @include customize(theme) {
    color: getValue(darkColor);
  }
}

form span {
  @include customize(theme) {
    color: getValue(darkColor);
  }
}

.form-group label {
  @include customize(theme) {
    color: getValue(darkColor);
  }
}

.required {
  @include customize(theme) {
    color: getValue(required);
  }
}

.error {
  color: #e52f48 !important;
}

.paymentCard {
  border: 1px solid white;
  padding: 16px;
  margin: 16px;
  background-color: white;
  box-shadow: 1px 1px 5px pink;
  border-radius: 10px;
  min-height: 370px;
}

.react-responsive-modal-closeButton:focus {
  outline: none;
}

.react-responsive-modal-modal {
  @include customize(theme) {
    background-color: getValue(backgroundColor);
  }
}

.forgot-link:hover {
  @include customize(theme) {
    color: getValue(buttonTextColor);
  }
  text-decoration: none;
}

.red-text {
  color: $alizarin !important;
}
.home-requirements {
  ul {
    padding-left: 0;
  }
  p ul li {
    font-size: 18px !important;
  }
  li {
    display: flex;
  }
  p.footer {
    font-size: 18px;
  }
}

@media only screen and (min-width: 600px) {
  .homepage-title-margin {
    // margin-top: -23rem !important;
    margin-top: -32rem !important;
  }
}
